<script setup>
import { WdsLinkupsEventCardDetailed, WdsLinkupsEventCardSimple, WdsOverlay, WdsPillFloating } from '@wds/components';

const props = defineProps({
  tag: {
    type: String,
    required: false,
    default: 'section',
  },
  eventCount: {
    type: Number,
    required: true,
  },
  eventList: {
    type: Array,
    required: true,
  },
});

const nuxtApp = useNuxtApp();
const { t } = useI18n();
const { $tracking } = nuxtApp;
const router = useRouter();
const route = useRoute();

// COMPOSABLES
const dateTime = useDateTime();
const { useImage } = useImages();
const { useFormatter } = useFormatters();

// STORES
const languageStore = useLanguageStore();
const testingStore = useTestingStore();

// ### A/B TESTING FEATURE TOGGLES
const experimentLinkupEvents = testingStore.hasEnabledFeature('web_linkupsCallouts');
const experimentLinkupEventsCarousel = testingStore.hasEnabledFeature('web_linkupsCalloutsEnableInlineCarousel');

// ### REFS
const linkupsContainer = ref(null);
const linkupsCarousel = ref(null);
const linkupsCarouselDetailed = ref(null);

// ### DATA
const linkupsCarouselBoundaries = ref({
  left: true,
  right: false,
});
const showLinkupsOverlay = ref(false);
const activeLinkupInGallery = ref(0);
const bodyScrollTop = ref(0);
const commonTrackingParameters = {
  linkups_web_cohort: experimentLinkupEvents ? 'variation' : 'control',
  linkups_counter_flag: experimentLinkupEvents,
  linkups_carousel_flag: experimentLinkupEventsCarousel,
  linkups_count_displayed: props.eventCount || 0,
};

// ### CONSTANTS
const trackedEventsFromCarousel = [];

// ### COMPUTED
const getLinkupEventsForCarousel = computed(() => {
  if (!props.eventList?.length) {
    return [];
  }
  return props.eventList.map((linkup) => {
    const linkupStartDay = dateTime.formatToHumanReadableShortDate(
      linkup.startTime,
      languageStore.getCurrentLanguage.language,
    );

    const linkupPrice = linkup?.price?.value
      ? useFormatter.formatCurrency(linkup?.price?.value, linkup?.price?.currency)
      : t('t_LINKUPPRICEFREE');

    const linkupAudience
      = linkup?.participantsType === 1 ? t('t_LINKUPAUDIENCEALLINCITY') : t('t_LINKUPAUDIENCEALLINHOSTEL');

    return {
      id: linkup.idGathering,
      name: linkup.name,
      type: t('t_LINKUPHOSTELEVENT'),
      image: {
        small: useImage.getOptimizedImageUrl(linkup.imageUrl, { width: 160 }),
        large: useImage.getOptimizedImageUrl(linkup.imageUrl, { width: 480 }),
      },
      date: linkupStartDay,
      price: linkupPrice,
      host: {
        name: linkup?.hostelOwner?.name,
        image: linkup.hostelOwner.imageUrl,
      },
      location: { city: linkup?.cityName },
      audience: linkupAudience,
      description: linkup?.description,
      additionalInfo: t('t_LINKUPADDITIONALINFO'),
      translations: {
        tHostedBy: t('t_LINKUPHOSTEDBY'),
        tStayHere: t('t_LINKUPSTAYHERETOJOIN'),
        tBookNow: t('t_BOOKNOW'),
        tReadMore: t('t_READMORE'),
      },
    };
  });
});

const linkupsGalleryAtLeftBoundery = computed(() => {
  return activeLinkupInGallery.value <= 0;
});

const linkupsGalleryAtRightBoundery = computed(() => {
  return activeLinkupInGallery.value >= props.eventList.length - 1;
});

// ### METHODS
const getLinkupEventCardFullWidth = function () {
  const linkupsCarouselDetailedRef = toValue(linkupsCarouselDetailed);
  if (linkupsCarouselDetailedRef) {
    const linkupsFirstCard = linkupsCarouselDetailedRef.firstElementChild;
    const linkupsGap = Number.parseInt(getComputedStyle(linkupsFirstCard).marginRight);
    return linkupsFirstCard.offsetWidth + linkupsGap;
  }

  return null;
};

const handleLinkupEventCardSwipe = function () {
  if (!trackedEventsFromCarousel.includes(activeLinkupInGallery.value)) {
    $tracking.triggerLinkupEventAction({
      action: 'Linkups Reel Swiped',
      ...commonTrackingParameters,
      linkups_gallery_reels_count: props.eventList?.length,
      linkups_gallery_position_viewed: activeLinkupInGallery.value,
    });
    trackedEventsFromCarousel.push(activeLinkupInGallery.value);
  }
};

const handleLinkupsSectionScroll = async function (e) {
  const linkupsCarouselRef = toValue(linkupsCarousel);
  if (linkupsCarouselRef) {
    linkupsCarouselBoundaries.value.left = e.currentTarget?.scrollLeft === 0;
    linkupsCarouselBoundaries.value.right
      = e.currentTarget?.scrollLeft === linkupsCarouselRef?.scrollWidth - linkupsCarouselRef?.offsetWidth;
  }
};

const handleLinkupsGalleryScroll = async function (e) {
  const scrollEnd = e.currentTarget.scrollLeft;
  const cardWidth = getLinkupEventCardFullWidth();
  if (cardWidth) {
    activeLinkupInGallery.value = Math.floor(scrollEnd / cardWidth);
    handleLinkupEventCardSwipe();
  }
};

const openLinkupEventsDetails = async function () {
  bodyScrollTop.value = window.scrollY;
  showLinkupsOverlay.value = true;
  await nextTick();

  linkupDetailedEventMoveToActive('instant');

  const linkupsCarouselDetailedRef = toValue(linkupsCarouselDetailed);
  setTimeout(() => {
    linkupsCarouselDetailedRef?.addEventListener('scrollend', handleLinkupsGalleryScroll, false);
  }, 100);
};

const closeLinkupEventsDetails = async function () {
  showLinkupsOverlay.value = false;
  await router.replace({
    hash: '',
    query: route.query,
  });
  await nextTick();

  setTimeout(() => window.scrollTo(0, bodyScrollTop.value), 0);

  const linkupsCarouselDetailedRef = toValue(linkupsCarouselDetailed);
  linkupsCarouselDetailedRef?.removeEventListener('scrollend', handleLinkupsGalleryScroll, false);
};

const handleLinkupEventCardClick = function (eventPos) {
  activeLinkupInGallery.value = Number.isInteger(eventPos) ? eventPos : 0;
  openLinkupEventsDetails();

  $tracking.triggerLinkupEventAction({
    action: 'Linkups Carousel Clicked',
    ...commonTrackingParameters,
    linkups_carousel_position_clicked: activeLinkupInGallery.value,
    linkups_carousel_count: props.eventList?.length,
  });
};

const handleLinkupEventReadMoreClick = function (eventPos) {
  activeLinkupInGallery.value = eventPos;
  linkupDetailedEventMoveToActive();

  $tracking.triggerLinkupEventAction({
    action: 'Linkups Read More Clicked',
    ...commonTrackingParameters,
    linkups_gallery_position_clicked: Number.isInteger(eventPos) ? eventPos : null,
  });
};

const handleLinkupEventBookNowClick = function (eventPos) {
  closeLinkupEventsDetails();

  $tracking.triggerLinkupEventAction({
    action: 'Linkups Book Now Clicked',
    ...commonTrackingParameters,
    linkups_gallery_position_clicked: Number.isInteger(eventPos) ? eventPos : null,
  });

  nuxtApp.callHook('property-availability:open');
};

const linkupSimpleEventsScroll = function (direction) {
  const validDirection = [1, -1].includes(direction) ? direction : 1;
  const linkupsCarouselRef = toValue(linkupsCarousel);
  const linkupsFirstCard = linkupsCarouselRef.firstElementChild;
  const linkupsGap = Number.parseInt(getComputedStyle(linkupsFirstCard).marginRight);
  const slideOffset = (linkupsFirstCard.offsetWidth + linkupsGap) * validDirection;

  linkupsCarouselRef?.scrollBy({
    left: slideOffset,
    top: 0,
    behavior: 'smooth',
  });
};

const linkupDetailedEventsScroll = function (direction) {
  const validDirection = [1, -1].includes(direction) ? direction : 1;
  const linkupsCarouselDetailedRef = toValue(linkupsCarouselDetailed);

  if (
    (validDirection < 0 && linkupsGalleryAtLeftBoundery.value)
    || (validDirection > 0 && linkupsGalleryAtRightBoundery.value)
  ) {
    return;
  }

  if (linkupsCarouselDetailedRef) {
    activeLinkupInGallery.value += validDirection;
    linkupDetailedEventMoveToActive();
    handleLinkupEventCardSwipe();
  }
};

function linkupDetailedEventMoveToActive(behaviour = 'smooth') {
  const linkupsCarouselDetailedRef = toValue(linkupsCarouselDetailed);
  if (linkupsCarouselDetailedRef) {
    const linkupsGalleryCards = linkupsCarouselDetailedRef.querySelectorAll('.linkups-carousel-detailed-card');
    const nextCard = linkupsGalleryCards?.[activeLinkupInGallery.value];
    if (nextCard) {
      nextCard?.scrollIntoView({
        behavior: behaviour,
        inline: 'center',
      });
    }
  }
}

watch(
  () => route.hash,
  (newHash, oldHash) => {
    if (oldHash === '#linkups' && newHash === '') {
      closeLinkupEventsDetails();
    }
  },
);

onMounted(() => {
  linkupsCarousel?.value?.addEventListener('scroll', handleLinkupsSectionScroll, false);
});

onUnmounted(() => {
  linkupsCarousel?.value?.removeEventListener('scroll', handleLinkupsSectionScroll, false);
});
</script>

<template>
  <div
    ref="linkupsContainer"
    class="linkups-container"
  >
    <component
      :is="tag"
      v-if="getLinkupEventsForCarousel?.length"
      class="linkups-carousel-simple"
    >
      <h2>{{ $t('t_LINKUPEVENTS') }}</h2>
      <span class="linkups-subtitle">{{ $t('t_LINKUPBOOKNOWTOJOIN') }}</span>
      <div class="linkups-navigation">
        <WdsPillFloating
          icon-start="arrow-left"
          aria-label="left arrow"
          :disabled="linkupsCarouselBoundaries.left"
          @click="linkupSimpleEventsScroll(-1)"
        />
        <WdsPillFloating
          icon-start="arrow-right"
          aria-label="right arrow"
          :disabled="linkupsCarouselBoundaries.right"
          @click="linkupSimpleEventsScroll(1)"
        />
      </div>
      <div
        ref="linkupsCarousel"
        class="linkups-carousel"
      >
        <WdsLinkupsEventCardSimple
          v-for="(linkup, idx) in getLinkupEventsForCarousel"
          :key="`linkup${linkup.id}`"
          :image="linkup.image.small"
          :title="linkup.name"
          :date="linkup.date"
          class="linkups-carousel-card"
          href="#linkups"
          @click="handleLinkupEventCardClick(idx)"
        />
      </div>
    </component>
    <WdsOverlay
      v-if="showLinkupsOverlay"
      class="linkups-overlay"
      :jump-to-top-on-close="false"
      @mousedown="closeLinkupEventsDetails"
    >
      <div class="linkups-carousel-detailed-navigation nav-left">
        <WdsPillFloating
          icon-start="arrow-left"
          aria-label="left arrow"
          :disabled="linkupsGalleryAtLeftBoundery"
          @click="linkupDetailedEventsScroll(-1)"
          @mousedown.stop
        />
      </div>
      <div class="linkups-carousel-detailed-navigation nav-right">
        <WdsPillFloating
          icon-start="arrow-right"
          aria-label="right arrow"
          :disabled="linkupsGalleryAtRightBoundery"
          @click="linkupDetailedEventsScroll(1)"
          @mousedown.stop
        />
      </div>
      <div
        ref="linkupsCarouselDetailed"
        class="linkups-carousel-detailed"
      >
        <WdsLinkupsEventCardDetailed
          v-for="(linkup, idx) in getLinkupEventsForCarousel"
          :key="`linkupDetail${linkup.id}`"
          :event-id="linkup.id"
          :event-type="linkup?.type"
          :event-image="linkup?.image?.large"
          :event-title="linkup?.name"
          :event-date="linkup?.date"
          :event-price="linkup?.price"
          :event-host-name="linkup?.host?.name"
          :event-host-avatar="linkup?.host?.image"
          :event-location="linkup?.location?.city"
          :event-audience="linkup?.audience"
          :event-description="linkup?.description"
          :event-additional-info="linkup?.additionalInfo"
          :translations="linkup?.translations"
          class="linkups-carousel-detailed-card"
          @close-linkup-sheet="closeLinkupEventsDetails"
          @book-now-click="handleLinkupEventBookNowClick(idx)"
          @show-more-details="handleLinkupEventReadMoreClick(idx)"
          @mousedown.stop
        />
      </div>
    </WdsOverlay>
  </div>
</template>

<style scoped lang="scss">
.linkups-container {
  .linkups-carousel-simple {
    position: relative;
    color: $wds-color-purple;
    background-color: $wds-color-purple-lightest;
    border-radius: $wds-spacing-l;
    padding-inline: $wds-spacing-m;
    padding-block: $wds-spacing-m $wds-spacing-s;

    .linkups-subtitle {
      @include body-2-reg;
    }

    .linkups-navigation {
      display: none;
      position: absolute;
      top: $wds-spacing-m;
      right: $wds-spacing-m;
    }

    .linkups-carousel {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      overflow-x: auto;

      @include no-scrollbar;

      .linkups-carousel-card {
        margin: $wds-spacing-s $wds-spacing-m $wds-spacing-s 0;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    @include tablet-large {
      .linkups-navigation {
        display: flex;
        gap: $wds-spacing-m;
      }
    }
  }

  .linkups-overlay {
    .linkups-carousel-detailed-navigation {
      display: none;
    }

    .linkups-carousel-detailed {
      position: relative;
      max-height: 600px;
      height: 100vh;
      width: 100vw;
      padding-inline: 50%;
      display: flex;
      overflow: auto hidden;
      scroll-behavior: smooth;
      scroll-snap-type: x mandatory;

      @include no-scrollbar;

      .linkups-carousel-detailed-card {
        margin: 0 $wds-spacing-l $wds-spacing-s 0;
        min-width: min(90vw, wds-rem(480px));
        scroll-snap-align: center;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    @include desktop {
      .linkups-carousel-detailed-navigation {
        display: flex;
        position: absolute;
        top: calc(50% - 20px);
        z-index: 700;

        &.nav-left {
          left: calc(50% - 20px - 240px);
        }

        &.nav-right {
          right: calc(50% - 20px - 240px);
        }
      }

      .linkups-carousel-detailed {
        padding-inline: calc(50% - 240px);

        .linkups-carousel-detailed-card {
          margin-right: wds-rem(72px);
          min-width: wds-rem(480px);
        }
      }
    }
  }
}
</style>
