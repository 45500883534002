<script setup>
import groupsConfig from '@/config/groups';
import { WdsButtonPrimary, WdsIcon, WdsPaymentMethods, WdsSearchFormInline } from '@wds/components';
import { commonValues } from '~/config/common.js';

const props = defineProps({
  propertyId: {
    type: Number,
    required: true,
  },
  propertySlug: {
    type: String,
    required: true,
  },
  propertyCity: {
    type: String,
    required: true,
  },
  propertyMinPrice: {
    type: String,
    required: false,
    default: '',
  },
});

// ### STORE/PINIA
const searchStore = useSearchStore();

// ### COMPOSABLES
const nuxtApp = useNuxtApp();
const searchData = useSearch();
const { useUrl } = useUrls();
const { useFormatter } = useFormatters();
const dateTime = useDateTime();

// ### DATA
const paymentMethods = [
  commonValues.PAYMENTMETHODSMAPPING.Visa,
  commonValues.PAYMENTMETHODSMAPPING.Maestro,
  commonValues.PAYMENTMETHODSMAPPING.Mastercard,
  commonValues.PAYMENTMETHODSMAPPING.JCB,
];
const displayDatePicker = ref(false);

// ### COMPUTED
const searchFormInlineParams = computed(() => ({
  size: 'small',
  displayDatePicker: displayDatePicker.value,
  ...searchData.getSearchFormCommonParams.value,
}));

const handleCheckAvailability = function () {
  const availabilityDrawer = document?.querySelector('.availability-drawer');
  const isAvailabilityPanelDisplayed = getComputedStyle(availabilityDrawer).display === 'none';
  if (isAvailabilityPanelDisplayed) {
    displayDatePicker.value = true;
  } else {
    nuxtApp.callHook('search-form:open', 'dates');
  }
};

const handleAvailabilitySubmit = function () {
  const isGroupBooking = searchStore.getSearchNumberOfGuests >= groupsConfig.minGuests - 1;

  const propertyLocationData = {
    propertyId: props.propertyId,
    urlFriendlyCity: useFormatter.normalizeUrl(props.propertyCity),
    urlFriendlyProperty: useFormatter.normalizeUrl(props.propertySlug),
  };

  const propertyLocationQuery = {
    from: dateTime.formatToFlat(searchStore.getSearchCheckIn),
    to: dateTime.formatToFlat(searchStore.getSearchCheckOut),
    guests: searchStore.getSearchNumberOfGuests,
  };

  if (isGroupBooking && searchStore.getSearchGroupType) {
    propertyLocationQuery.groupType = searchStore.getSearchGroupType?.value;
  }

  let redirectUrl = useUrl.getPropertyPageUrlDynamic(propertyLocationData, propertyLocationQuery);

  if (isGroupBooking && searchStore.getSearchGroupAge?.length) {
    searchStore.getSearchGroupAge.forEach((groupAge) => {
      redirectUrl += `&groupAgeRange=${groupAge}`;
    });
  }

  setTimeout(() => {
    navigateTo(redirectUrl, { external: true });
  }, 100);
};

// ## HOOKS
nuxtApp.hooks.hook('property-availability:open', () => {
  handleCheckAvailability();
});
</script>

<template>
  <div class="availability-container">
    <div class="availability-box">
      <div class="availability-title">
        {{ $t('t_CHECKAVAILABILITY') }}
      </div>
      <div class="availability-search-form">
        <WdsSearchFormInline
          class="availability-search-form-container"
          v-bind="searchFormInlineParams"
          @destination-selected="searchData.handleDestinationFieldClicked"
          @destination-input="searchData.handleDestinationSearch"
          @destination-item-selected="searchData.handleDestinationItemSelected"
          @recent-destination-selected="searchData?.handleRecentDestinationItemSelected"
          @current-location="searchData.handleCurrentLocation"
          @dates-selected="searchData.handleDatesFieldClicked"
          @dates-changed="searchData.handleDatesSelected"
          @guests-selected="searchData.handleGuestsFieldClicked"
          @guests-change="searchData.handleGuestsNumberChanged"
          @guests-group-type-change="searchData.handleGuestsGroupTypeChanged"
          @guests-group-ages-change="searchData.handleGuestsGroupAgesChanged"
        />
      </div>
      <div class="availability-search-submit">
        <WdsButtonPrimary
          :text="$t('t_RWDSEARCH')"
          :full-width="true"
          @click="handleAvailabilitySubmit"
        />
      </div>
      <div class="availability-extra-info">
        <WdsPaymentMethods :payment-methods-list="paymentMethods" />
        <div class="extra-info-message">
          <WdsIcon asset="clock" />
          <span>{{ $t('t_BOOKINGONLYTAKES2MINUTES') }}</span>
        </div>
        <div class="extra-info-message">
          <WdsIcon asset="confirmation" />
          <span>{{ $t('t_INSTANTCONFIRMATION') }}</span>
        </div>
      </div>
    </div>
    <div class="availability-drawer">
      <div
        v-if="propertyMinPrice"
        class="lowest-price-wrapper"
      >
        <p>{{ $t('t_PRICESFROM') }}</p>
        <span>€18.00</span>
      </div>
      <WdsButtonPrimary
        :text="$t('t_CHECKAVAILABILITY')"
        :full-width="!propertyMinPrice"
        @click="handleCheckAvailability"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.availability-container {
  .availability-box {
    display: none;
  }

  .availability-drawer {
    display: flex;
    gap: $wds-spacing-s;
    background-color: $wds-color-white;
    box-shadow: $wds-shadow-dark-l;
    border-radius: $wds-border-radius-l $wds-border-radius-l 0 0;
    padding: $wds-spacing-m;

    .lowest-price-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;

      p {
        @include body-1-reg;
      }

      span {
        @include title-5-bld;
      }
    }
  }

  @include tablet-large {
    .availability-box {
      display: flex;
      flex-direction: column;
      gap: $wds-spacing-m;
      padding: $wds-spacing-l $wds-spacing-m;
      box-shadow: $wds-shadow-light-m;
      border-radius: $wds-border-radius-xxl;
      background-color: $wds-color-white;

      .availability-title {
        @include title-3-bld;
      }

      .availability-extra-info {
        display: flex;
        flex-direction: column;
        gap: $wds-spacing-m;
        padding: $wds-spacing-s $wds-spacing-s 0;

        .extra-info-message {
          display: flex;
          align-items: center;
          gap: $wds-spacing-s;
          color: $wds-color-ink;
          fill: $wds-color-ink;

          @include body-1-reg;
        }
      }
    }

    .availability-drawer {
      display: none;
    }
  }
}
</style>
